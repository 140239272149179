import Head from "next/head";
import '../styles/globals.css';
function MyApp({ Component, pageProps }) {
  return(
    <>
    <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=5.0"/>
        <meta name="generator" content="Opseazy"/>
    </Head>
     <Component {...pageProps} />
     </>)
}

export default MyApp
